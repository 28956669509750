import React, { useState, useEffect } from 'react';
import './App.css';
import Map, { MapIds } from 'components/Map/Map';
import PartnerList from 'components/PartnerList/PartnerList';
import Controls from 'components/Controls/Controls';
import { fetchPartners, Partner } from 'requests/partners';
import {
  values,
  map,
} from 'lodash';

type configType = {
  [key: string]: string,
};
const SUBTITLES: configType = {
  "recup": "RECUP-Partner",
  "all": "RECUP-Partner",
  "rebowl": "REBOWL-Partner",
}
const LOGOS: configType = {
  "recup": "logo-recup.png",
  "all": "logo-recup.png",
  "rebowl": "logo-rebowl.svg",
}

type Configuration = {
  center: [number, number],
  type: string,
  resultCount: number,
  title: string,
  style: string,
  size: string,
};

function App() {
  const params = new URL(window.location.href).searchParams;

  const savedCenter = params.getAll("center");
  const [options, setInternalOptions] = useState<Configuration>({
    center: map(savedCenter.length ? savedCenter : ['48.1351253', '11.5819806'], parseFloat) as [number, number],
    type: params.get("type") || 'recup',
    resultCount: parseInt(params.get("resultCount") || '20', 10),
    title: params.get("title") || 'München',
    style: params.get("style") || 'color',
    size: params.get("size") || 'a4',
  });
  const setOptions = (options: Configuration) => {
    setInternalOptions(options as any);
    const params = new URLSearchParams();

    params.set("type", options.type);
    params.append("center", options.center[0].toString());
    params.append("center", options.center[1].toString());
    params.set("resultCount", options.resultCount.toString());
    params.set("title", options.title);
    params.set("style", options.style);
    params.set("size", options.size);

    window.history.pushState(options, document.title, '?' + params.toString());
  }

  const [loaded, setLoaded] = useState(false);
  const [partners, setPartners] = useState<Partner[]>([]);

  useEffect(() => {
    const mapsTag = document.createElement('script');
    mapsTag.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBfrKclvayBiW0e1mb3INI4wAtnbXCbXP4&libraries=places&map_ids=${values(MapIds)}`;
    mapsTag.async = true;
    window.document.body.appendChild(mapsTag);

    mapsTag.addEventListener('load', () => {
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPartners(options.type);

      setPartners(result);
    }

    fetchData();
  }, [options.type]);

  if (!loaded) {
    return null;
  }

  return (
    <div className={ `App App-style-${options.style} App-type-${options.type} App-size-${options.size}` }>
      <Controls
        options={options}
        setOption={(key: string, value: any) => setOptions({ ...options, [key]: value })}
        setOptions={(newOptions: object) => setOptions({ ...options, ...newOptions })}
      />

      <section className="section-print">
        <img 
          className="logo"
          src={ LOGOS[options.type] }
          alt={ SUBTITLES[options.type] }
        />
        <header>
          <h1>{ options.title }</h1>
          <h2>
            { SUBTITLES[options.type] }
            <br />
            in Deiner Nähe
          </h2>
        </header>
        <Map
          options={options}
          partners={partners}
        />
        <PartnerList
          options={options}
          partners={partners}
        />
      </section>
    </div>
  );
}

export default React.memo(App, () => true);

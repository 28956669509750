import {
  sortBy,
  slice,
  map,
} from 'lodash';

const ENDPOINT_URL = 'https://api.rbwl.de/partners/list';

export interface Partner {
  id: number,
  name: string,
  type: string,
  street1: string,
  street2: string,
  city: string,
  coordinates: number[],
  index: number,
}

export const fetchPartners = async (type: string): Promise<Partner[]> => {
  const response = await fetch(ENDPOINT_URL + '?search=' + type)

  const result = await response.json();

  return result["locations"];
}

const rad = function(x: number) {
  return x * Math.PI / 180;
};

// Adapted from
// https://stackoverflow.com/questions/1502590/calculate-distance-between-two-points-in-google-maps-v3
const getDistance = (p1: number[], p2: number[]) => {
  const R = 6378137; // Earth’s mean radius in meters
  const dLat = rad(p2[0] - p1[0]);
  const dLong = rad(p2[1] - p1[1]);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
};

export const filterPartners = (partners: Partner[], center: number[], resultCount: number) => {
    const byDistance = sortBy(
      partners,
      (partner) => getDistance(center, partner.coordinates)
    ) as Partner[];
    const withIndex = map(
      byDistance,
      (partner, index) => ({
        ...partner,
        index: index + 1,
      })
    )
    return slice(withIndex, 0, resultCount);
}
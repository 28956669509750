import React, { useEffect, useRef, useState } from 'react';
import styles from './Map.module.css';
import { filterPartners, Partner } from 'requests/partners';
import {
  map,
  each,
} from 'lodash';

type ConfigMap = {
  [key: string]: string,
}
const Icons: ConfigMap = {
  'recup': '/markers/recup.png',
  'rebowl': '/markers/rebowl.png',
  'recup_rebowl': '/markers/recup_rebowl.png',
}
const IconColors: ConfigMap = {
  'recup': '#000',
  'rebowl': '#fff',
  'recup_rebowl': '#000',
}

export const MapIds: ConfigMap = {
  'color': '585d0a76e579ab38',
  'bw': '817f60cf46ebefb',
}

type MapProps = {
  options: any,
  partners: Partner[],
}

function c2ll(coordinates: number[]) {
  return {
    lat: coordinates[0],
    lng: coordinates[1],
  };
}

const Map: React.FC<MapProps> = ({ options, partners }) => {
  const googleMapRef = useRef<any>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

  const initMap = () => {
    // https://developers.google.com/maps/documentation/javascript/reference/map
    mapRef.current = new window.google.maps.Map(googleMapRef.current, {
      center: c2ll(options.center),
      zoom: 12,
      mapId: MapIds[options.style],
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      scaleControl: true,
    } as any);
  };

  useEffect(() => {
    initMap();
    setMapLoaded(true);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => {
    // Only initialize again if we are fully initialized
    mapLoaded && initMap();
  }, [options.style, options.size]);

  const gmap = mapRef.current!;

  useEffect(() => {
    gmap && options.bounds && gmap.fitBounds(options.bounds);
  }, [options.bounds, gmap]);

  useEffect(() => {
    if (!mapLoaded || partners === []) {
      return;
    }

    const results = filterPartners(partners, options.center, options.resultCount);

    const bounds = new window.google.maps.LatLngBounds();
    each(markers, (marker) => marker.setMap(null));
    setMarkers(map(results, (partner, index) => {
      bounds.extend(c2ll(partner.coordinates));
      // https://developers.google.com/maps/documentation/javascript/markers
      return new google.maps.Marker({
        position: c2ll(partner.coordinates),
        // https://developers.google.com/maps/documentation/javascript/reference#MarkerLabel
        label: {
          text: `${index + 1}`,
          color: IconColors[partner.type] || '#f00',
          fontWeight: 'bold',
          className: partner.type === "rebowl" ? styles.bubbleRebowl : styles.bubbleRecup,
        } as any,
        map: gmap,
        icon: {
          url: Icons[partner.type],
          scaledSize: new google.maps.Size(25, 30),
          labelOrigin: new google.maps.Point(12.5, 12.5),
          anchor: new google.maps.Point(30, 12.5),
        },
        title: partner.name,
      });
    }));

    gmap.setCenter(c2ll(options.center));
    gmap.fitBounds(bounds);
    // eslint-disable-next-line
  }, [partners, options.resultCount, options.center, options.size, gmap, mapLoaded]);

  return (
    <div
      id="map"
      className={ styles[`map-${options.size}`] }
      ref={googleMapRef}
    />
  );
};

export default Map;

import { filter, first, includes } from 'lodash';
import React, { useEffect } from 'react';
import styles from './Controls.module.css';

type ControlProps = {
  options: any,
  setOption: (key: string, value: any) => any,
  setOptions: (newOptions: object) => any,
}

const Controls: React.FC<ControlProps> = ({ options, setOption, setOptions }) => {
  useEffect(() => {
    const input = document.getElementById("places-search") as HTMLInputElement;
    const autoComplete = new window.google.maps.places.Autocomplete(input, {
      types: ['geocode', 'establishment'],
      componentRestrictions: {
        country: [
          'de',
          'at',
        ],
      },
    });

    // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
    autoComplete.setFields(["geometry.location", "name", "address_components"]);
    autoComplete.addListener("place_changed", () => {
      const address = autoComplete.getPlace().address_components!;
      const location = autoComplete.getPlace().geometry!.location;

      const city = first(filter(address, a => (
        includes(a.types, "locality")
      ))) || address[2];

      setOptions({
        'center': [location.lat(), location.lng()],
        'title': city["long_name"],
      });
    });
  }, [setOptions])

  return (
    <div className={styles.container}>
      <h3>Konfiguriere Deine individuelle Partner-Map</h3>
      <br />
      <label className={ styles.label }>Adresse oder Stadt eingeben</label>
      <p>Gib die Adresse Deiner Ausgabestelle ein, damit Du an erster Stelle in der Kartenansicht erscheinst.</p>
      <input
        id="places-search"
        className={styles.input}
      />
      <label className={ styles.label }>Titel der Karte</label>
      <input id="input-titel-karte" 
        className={styles.input}
        onChange={(e) => setOption("title", e.target.value)}
        value={options.title}
      />
      <label className={ styles.label }>Standort-Typ</label>
      <select id="select-standort-typ"
        value={ options.type }
        onChange={(e) => setOption("type", e.target.value)}
        className={ styles.select }
      >
        <option value="rebowl">Rebowl</option>
        <option value="recup">Recup</option>
        <option value="all">Alle</option>
      </select>
      <label className={ styles.label }>Größe</label>
      <select id="select-groesse"
        value={ options.size }
        onChange={(e) => setOption("size", e.target.value)}
        className={ styles.select }
      >
        <option value="a4">A4</option>
        <option value="a5">A5</option>
      </select>
      <label className={ styles.label }>Anzahl Ergebnisse</label>
      <p>
        A4: 20 Einträge auf der ersten Seite, 60 je folgende -&gt; 80 ist 2-seitig
        <br />
        A5: 16 Einträge auf der ersten Seite, 50 je folgende -&gt; 66 ist 2-seitig
      </p>
      <input id="input-anzahl-ergebnisse"
        className={styles.input}
        onChange={(e) => setOption("resultCount", e.target.value ? parseInt(e.target.value, 10) : null)}
        value={options.resultCount}
      />
      <label className={ styles.label }>Style</label>
      <select id="select-style"
        value={ options.style }
        onChange={(e) => setOption("style", e.target.value)}
        className={ styles.select }
      >
        <option value="color">Farbig</option>
        <option value="bw">Schwarz-Weiß</option>
      </select>
      <br />
      <br />
      <label className={ styles.label }>Hinweis</label>
      <p>Die nummerierte Liste unter der Karte kannst Du auch im Nachhinein noch bearbeiten, indem Du einfach in die entsprechende Zeile klickst.</p>

      <h3>Drucken</h3>
      <p>Zum Ausdrucken müssen folgende Einstellungen im Druckdialog gesetzt werden:</p>
      <ul>
        <li>Seitengröße: Je nach Einstellung A4 oder A5</li>
        <li>Seitenränder: Keine</li>
        <li>Hintergrund drucken: Aktiv</li>
        <li>Aus technischen Gründen wird eine zusätzliche, leere, Seite erstellt. Diese muss nicht gedruckt werden!</li>
      </ul>
      <p>Um ein PDF zu erstellen, bitte auf "Karte Drucken" klicken und dann im Druckdialog ein PDF speichern.</p>
      <button id="karte-drucken" onClick={ () => window.print() }>Karte Drucken</button>
    </div>
  );
};

export default Controls;

import React from 'react';
import styles from './PartnerList.module.css';
import { filterPartners, Partner } from 'requests/partners';
import {
  map,
  chunk,
} from 'lodash';

type PartnerListProps = {
  options: {
    size: string,
    center: any,
    resultCount: number,
  },
  partners: Partner[],
}

function renderPartners(partners: Partner[], index: number, first=false) {
  return (
    <div className={ first ? styles.firstPage : styles.fullPage } key={ index }>
      <div className={ styles.container }>
        { map(partners, (partner) => (
          <p className={ styles.partner } key={ partner.id }>
            <strong>{ partner.index }.</strong>
            <span contentEditable suppressContentEditableWarning>{ partner.name }, { partner.street1 }</span>
          </p>
        ))}
      </div>
      <footer>
        <h3>REUSE RETURN REPEAT</h3>
      </footer>
    </div>
  );
}

type configType = {
  [key: string]: [number, number],
};
const PAGE_SIZES: configType = {
  a4: [20, 60],
  a5: [16, 50],
};
const PartnerList: React.FC<PartnerListProps> = ({ options, partners }) => {
  const results = filterPartners(partners, options.center, options.resultCount);

  const [initalPartnersperPage, partnersPerPage] = PAGE_SIZES[options.size];

  return (
    <div className={ styles[`page-${options.size}`] }>
      { renderPartners(results.slice(0, initalPartnersperPage), -1, true) }
      { results.length > initalPartnersperPage && (
        map(
          chunk(results.slice(initalPartnersperPage), partnersPerPage),
          (partner, index) => renderPartners(partner, index),
        )
      ) }
    </div>
  );
};

export default PartnerList;
